<template>
  <view>
    <my-tabs :tabs="tabs" v-model:activeTab="activeTab" class="tabs"></my-tabs>
    <div class="invoices_table_container">
      <my-table
        empty-text="暂无数据"
        :columns="columns"
        :is-page="true"
        :table-data="invoiceData.list || []"
        :total="invoiceData.total"
        :handle-arr="handleActions"
        @changePage="changePage"
      >
      </my-table>
    </div>
  </view>
</template>
<script>
import MyTabs from "@/components/MyTabs.vue";
import MyTable from "@/components/MyTable.vue";
import { connectState } from "@/utils/wyUtil";

export default {
  components: { MyTabs, MyTable },
  data() {
    return {
      curPage: 1,
      limit: 10,
      tabs: [
        { label: "申请中", value: 0, status: 0 },
        { label: "已开具", value: 1, status: 1 },
      ],
      activeTab: 0,
      columns: [
        { prop: "orderCode", label: "订单编号", showToolTip: true },
        { prop: "itemName", label: "商品名称", showToolTip: true },
        { prop: "email", label: "接收邮箱", showToolTip: true },
        {
          prop: "status",
          label: "状态",
          render: (row) => {
            return row.status === 2
              ? "待修改"
              : row.status === 1
              ? "已开具"
              : "申请中";
          },
          style: (row) => {
            // 审核状态 0待审核 1审核通过 2:已打回
            return row.status === 2
              ? "color:#F56C6C;"
              : row.status === 1
              ? "color:#43B633;"
              : "color:#FFAA1E;";
          },
        },
      ],
      handleActions: [
        {
          text: "查看发票",
          action: (row) => this.handleView(row),
          //   color: "#6D7278",
          disabled: (row) => {
            return row.status !== 1;
          },
        },
      ],
    };
  },
  computed: {
    ...connectState("order", ["invoiceData"]),
  },
  watch: {
    activeTab(val) {
      this.curPage = 1;
      this.$store.dispatch("order/getInvoicesData", {
        curPage: 1,
        limit: this.limit,
        status: val,
        type: 1,
      });
    },
  },
  created() {
    this.$store.dispatch("order/getInvoicesData", {
      curPage: this.curPage,
      limit: this.limit,
      status: this.activeTab,
      type: 1,
    });
  },
  methods: {
    changePage(page) {
      this.curPage = page;
      this.$store.dispatch("order/getInvoicesData", {
        curPage: page,
        limit: this.limit,
        status: this.activeTab,
        type: 1,
      });
    },
    handleView(row) {
      this.$router.push("/viewInvoice?type=1&code=" + row.orderCode);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
